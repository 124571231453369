import {
  ProductOptionsProvider,
  ShopifyAnalyticsConstants,
} from '@shopify/hydrogen';
import { ShopifyAnalyticData } from '../utils/client-analytics';
import { Faq } from '@snapi/types';
import type {
  Product,
  ProductVariant as ProductVariantType,
} from '@shopify/hydrogen/dist/esnext/storefront-api-types';
import type { PartialDeep } from 'type-fest';
import { ShopifyProductQuery } from '../utils/shopify-product-query';
import { getShopifyData } from '../utils/get-shopify-data';
import { useShopifyAnalytics } from '../utils/use-shopify-analytics';
import { getAnalyticsData } from '../utils/get-analytics-data';
import { LandingPage } from '../components/landing/landing-page';

export async function getStaticProps() {
  const {
    data: { product },
  } = await getShopifyData(ShopifyProductQuery, {});

  const initialVariant = product.variants.nodes[0];

  const { faq } = await (
    await fetch('https://snapihealth.hasura.app/api/rest/faq/landing')
  ).json();

  const analytics: ShopifyAnalyticData = await getAnalyticsData();

  return {
    props: {
      faq,
      product,
      initialVariant,
      analytics,
    },
  };
}

interface IndexProps {
  faq: Faq[];
  product: PartialDeep<Product>;
  initialVariant: PartialDeep<ProductVariantType>;
  analytics: ShopifyAnalyticData;
}

export default function Index({
  faq,
  product,
  initialVariant,
  analytics,
}: IndexProps) {
  useShopifyAnalytics(ShopifyAnalyticsConstants.pageType.home, analytics);

  return (
    <ProductOptionsProvider data={product} initialVariantId={initialVariant.id}>
      <LandingPage product={product} faq={faq} />
    </ProductOptionsProvider>
  );
}
