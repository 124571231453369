import Image from 'next/image';
import Tufts from './images/Tufts.png';
import Oregon from './images/Oregon.png';
import Harvard from './images/Harvard_University.png';
import Boston from './images/Boston.png';
import NewYork from './images/New_York-Presbyterian_Hospital.png';
import ReportScreen from './images/report-screen.png';
import Arrow from './images/arrow.png';
import LinesSmall from './images/lines-small.png';
import ProbioticCard from './images/probiotic-card.png';
import BacteriaCard from './images/bacteria-card.png';
import RecommendationCard from './images/recommendation-card.png';
import RecommendationCard2 from './images/recommendation-card-2.png';


interface HeroShotSectionProos {
    children: React.ReactNode;
}

export const HeroShot = ({ children }: HeroShotSectionProos) => {
    return (
        <section id="hero-shot" className="pt-0 bg-[#F2B3BC]">
            <div className="relative flex flex-col max-w-[1440px] mx-auto justify-center items-center bg-[#F2B3BC]">
                {children}
                <div className="flex flex-1 pt-24 md:pt-[160px] xl:pt-[80px] px-4 min-w-[290px] md:w-[656px] lg:w-[861px]">
                    <Image src={ReportScreen} alt="" />
                </div>
                <div className="absolute w-[42px] top-[440px] sml:top-[368px] md:w-[76px] md:top-[420px] md:right-[280px] lg:w-[84px] lg:right-[240px] lg:top-[440px] xl:right-[360px] xl:top-[350px]">
                    <Image src={Arrow} alt="" />
                </div>
                <div className="absolute w-[108px] top-[472px] left-[24px] sml:w-[160px] sml:top-[380px] sm:w-[200px] sm:top-[372px] md:w-[230px] md:top-[468px] md:left-[40px] lg:top-[510px] xl:top-[420px] xl:left-[180px]">
                    <Image src={RecommendationCard2} alt="" />
                </div>
                <div className="absolute w-[74px] top-[576px] right-[20px] xs:top-[600px] sml:w-[92px] sml:top-[560px] sm:w-[124px] sm:top-[340px] md:w-[140px] md:top-[380px] md:right-[48px] lg:top-[420px] xl:right-[200px]">
                    <Image src={ProbioticCard} alt="" />
                </div>
                <div className="absolute w-[107px] top-[640px] left-[32px] xs:top-[672px] sml:w-[140px] sml:top-[600px] sm:w-[200px] sm:left-[400px] sm:top-[680px] md:w-[232px] md:top-[820px] md:left-[500px] lg:top-[940px] lg:left-[720px] xl:left-[1000px] xl:top-[860px]">
                    <Image src={BacteriaCard} alt="" />
                </div>
                <div className="absolute w-[115px] top-[610px] left-2 xs:top-[630px] sml:w-[140px] sml:top-[568px] sm:w-[160px] sm:top-[640px] md:w-[208px] md:top-[760px] lg:top-[872px] xl:left-[200px] xl:top-[780px]">
                    <Image src={RecommendationCard} alt="" />
                </div>
                <div className="absolute w-[14px] top-[460px] left-4 sml:top-[372px] sm:w-[22px] sm:top-[360px] sm:left-3 md:top-[452px] md:left-6 lg:top-[488px] xl:left-[160px] xl:top-[400px]">
                    <Image src={LinesSmall} alt="" />
                </div>
                <div className="h-[100px] sm:hiddenbg-[#F2B3BC]"></div>
                <div className="justify-end flex flex-col container">
                    <h5 className="text-pink-dark mb-5 sm:mb-[30px] md:mb-6 text-center md:text-left">
                        our team comes from :
                    </h5>
                    <div className="flex flex-wrap justify-center gap-x-[30px] gap-y-3 max-w-[400px] sm:max-w-[520px] mx-auto md:max-w-full md:justify-between md:gap-0 md:mx-0">
                        <div className="w-[116px] lg:w-[148px]">
                            <Image src={Oregon} alt="Oregon University" />
                        </div>
                        <div className="w-[116px] lg:w-[204px]">
                            <Image src={Boston} alt="Boston Hospital" />
                        </div>
                        <div className="w-[116px] lg:w-[156px]">
                            <Image src={Harvard} alt="Harvard University" />
                        </div>
                        <div className="w-[116px] lg:w-[146px]">
                            <Image src={Tufts} alt="Tufts" />
                        </div>
                        <div className="w-[140px] lg:w-[226px]">
                            <Image src={NewYork} alt="New York presbyterian" />
                        </div>
                    </div>
                </div>
                <div className="lg:block h-[20px] bg-[#F2B3BC]"></div>
            </div>
        </section>
    );
};
