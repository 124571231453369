import Image from 'next/image';
import { Faq } from '@snapi/types';
import type { Product } from '@shopify/hydrogen/dist/esnext/storefront-api-types';
import type { PartialDeep } from 'type-fest';
import Link from 'next/link';
import { WhoSection } from './who';
import { WhatSectionA } from './what-a';
import { BuySection } from '../buy-section';
import { WhySection } from './why';
import { HowItWorkSection } from './how-it-works';
import { FaqSection } from './faq';
import { HowUseSection } from './how-use';
import { PhraseSection } from './phrase';
import { HeroShot } from './hero-shot';
import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Reviews } from './review';
import Underline from './hero-shot-a/images/underline.png';


interface LandingPageProps {
  faq: Faq[];
  product: PartialDeep<Product>;
}

export function LandingPage({ faq, product, }: LandingPageProps) {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const buySection = document.getElementById('buy');
      const herroShot = document.getElementById('hero-shot');
      if (buySection) {
        const buySectionTop = buySection.getBoundingClientRect().top;
        const buySectionBottom = buySection.getBoundingClientRect().bottom;
        const herroShotBottom = herroShot.getBoundingClientRect().bottom;
        setShowButton(
          buySectionTop > window.innerHeight && herroShotBottom < 0
          || buySectionBottom < window.innerHeight);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    <>
      <HeroShot>
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-center pt-32 md-pt-40 text-pink-dark z-10 font-bold text-[40px] leading-[50px] md:text-[60px] md:leading-[72px] lg:text-[80px] lg:leading-[88px] uppercase w-[290px] lg:w-[820px] sml:w-[400px] md:w-[612px]">
            Discover your baby’s {' '}
            <span className="text-white relative">gut health
              <div className="absolute w-[240px] right-0 top-6 md:w-[365px] md:top-8 lg:w-[478px] lg:top-10">
                <Image src={Underline} alt="" />
              </div>
            </span>
          </h1>

          <h4 className="s text-pink-dark pt-3 px-4 z-10 min-w-[290px] text-center">
            The first gut microbiome test for your children
          </h4>
          <div className="flex flex-1 pt-7 z-10 px-3 w-full sm:max-w-[360px]">
            <Link href="#buy-variants">
              <button className="pointer w-full h-[60px] rounded-[10px] uppercase shadow-[1px_2px_0_0_#7DA46C] bg-green text-body">
                <h5>Start now</h5>
              </button>
            </Link>
          </div>
          <AnimatePresence>
            {showButton && (
              <motion.div
                initial={{ y: "100%", opacity: 1 }}
                animate={{ y: "0%", transition: { duration: 0.5 } }}
                exit={{ y: "100%", transition: { duration: 0.5 } }}

                className="visible sm:invisible px-3 py-2 fixed mx-auto z-40 bottom-0 h-[108px] left-0 right-0 bg-white border-gray-light border-t-2">
                <div className='justify-center items-center'>
                  <Link href="#buy-variants">
                    <button className="pointer w-full h-[60px] rounded-[10px] uppercase shadow-[1px_2px_0_0_#7DA46C] bg-green text-body">
                      <h5>Start now</h5>
                    </button>
                  </Link>
                  <p className='text-[13px] text-body pt-2 text-center'>FREE SHIPPING • HSA / FSA eligible</p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </HeroShot>
      <WhoSection />
      <PhraseSection />
      <WhatSectionA />
      <WhySection />
      <HowItWorkSection />
      <HowUseSection />
      <BuySection preset="pink" product={product} />
      <Reviews />
      <FaqSection faq={faq} />
    </>
  );
}
